// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-curriculum-cmu-js": () => import("./../src/pages/curriculum/cmu.js" /* webpackChunkName: "component---src-pages-curriculum-cmu-js" */),
  "component---src-pages-curriculum-index-js": () => import("./../src/pages/curriculum/index.js" /* webpackChunkName: "component---src-pages-curriculum-index-js" */),
  "component---src-pages-curriculum-mit-js": () => import("./../src/pages/curriculum/mit.js" /* webpackChunkName: "component---src-pages-curriculum-mit-js" */),
  "component---src-pages-curriculum-stanford-js": () => import("./../src/pages/curriculum/stanford.js" /* webpackChunkName: "component---src-pages-curriculum-stanford-js" */),
  "component---src-pages-curriculum-ucb-js": () => import("./../src/pages/curriculum/ucb.js" /* webpackChunkName: "component---src-pages-curriculum-ucb-js" */),
  "component---src-pages-explore-js": () => import("./../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-topic-getting-started-foreword-js": () => import("./../src/pages/topic/getting-started/foreword.js" /* webpackChunkName: "component---src-pages-topic-getting-started-foreword-js" */),
  "component---src-pages-topic-getting-started-index-js": () => import("./../src/pages/topic/getting-started/index.js" /* webpackChunkName: "component---src-pages-topic-getting-started-index-js" */),
  "component---src-pages-topic-getting-started-language-suggestion-js": () => import("./../src/pages/topic/getting-started/languageSuggestion.js" /* webpackChunkName: "component---src-pages-topic-getting-started-language-suggestion-js" */),
  "component---src-pages-topic-getting-started-programming-language-js": () => import("./../src/pages/topic/getting-started/programmingLanguage.js" /* webpackChunkName: "component---src-pages-topic-getting-started-programming-language-js" */),
  "component---src-pages-topic-getting-started-top-languages-js": () => import("./../src/pages/topic/getting-started/topLanguages.js" /* webpackChunkName: "component---src-pages-topic-getting-started-top-languages-js" */),
  "component---src-pages-topic-topic-header-js": () => import("./../src/pages/topic/topicHeader.js" /* webpackChunkName: "component---src-pages-topic-topic-header-js" */),
  "component---src-templates-course-js": () => import("./../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-glossary-js": () => import("./../src/templates/glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

